import { render, staticRenderFns } from "./Weblog.vue?vue&type=template&id=20987a01&scoped=true&"
import script from "./Weblog.vue?vue&type=script&lang=js&"
export * from "./Weblog.vue?vue&type=script&lang=js&"
import style0 from "./Weblog.vue?vue&type=style&index=0&id=20987a01&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20987a01",
  null
  
)

export default component.exports