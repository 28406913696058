<template>
  <div>
    <main class="main product-details-main">
      <PagesPath
        v-if="blog != null"
        :pathTitle1="'پست ها'"
        :pathTo1="'/weblog-list'"
        :pathTitle2="blog != null ? blog.post.title : ''"
      ></PagesPath>
      <div class="weblog-list-grid container text-right mt-md-5 mt-3">
        <div class="weblog-list-main mb-md-5 mb-4">
          <section class="weblog-details bg-white box-shaddow20 px-3 py-4 mb-5">
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <h6 v-else class="text-color-444 fontsize-large">
              {{ blog.post.title }}
            </h6>
            <hr />
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <div
              v-else
              class="d-flex flex-wrap justify-content-around align-items-baseline mb-3"
            >
              <div class="weblog-item-publisher d-flex align-items-baseline">
                <div class="article-user-img ml-1">
                  <!-- <img :src="blog.post.image.url" alt=""> -->
                </div>
                <span class="text-color-666 fontsize-small weight-bold">{{
                  blog.post.creatorable.name
                }}</span>
              </div>
              <div class="weblog-list-post-date">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 22.0001C6.48 22.0001 2 17.5301 2 12.0001C2 6.48011 6.48 2.00011 12 2.00011C17.53 2.00011 22 6.48011 22 12.0001C22 17.5301 17.53 22.0001 12 22.0001ZM15.19 15.7101C15.31 15.7801 15.44 15.8201 15.58 15.8201C15.83 15.8201 16.08 15.6901 16.22 15.4501C16.43 15.1001 16.32 14.6401 15.96 14.4201L12.4 12.3001V7.68011C12.4 7.26011 12.06 6.93011 11.65 6.93011C11.24 6.93011 10.9 7.26011 10.9 7.68011V12.7301C10.9 12.9901 11.04 13.2301 11.27 13.3701L15.19 15.7101Z"
                    fill="#999"
                  />
                </svg>
                <span
                  class="haederBlogDetail fontsize-small text-color-666 weight-bold pr-1"
                  >تاریخ انتشار :</span
                >
                <span class="fontsize-small text-color-999">{{
                  blog.post.published_at | Date
                }}</span>
              </div>
              <div class="fontsize-small weblog-list-post-date">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
                    fill="#999"
                  />
                </svg>
                <span
                  class="haederBlogDetail text-color-666 weight-bold pr-1"
                  >{{ blog.post.view_count }}</span
                >
                <span class="text-color-999">بازدید</span>
              </div>
              <div class="fontsize-small weblog-list-post-date">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z"
                    fill="#999"
                  />
                </svg>
                <span
                  v-if="blog.post.comments.length != 0"
                  class="text-color-666 weight-bold"
                  >{{ blog.post.comments.length }}</span
                >
                <span v-else class="text-color-666 weight-bold pr-1">0</span>
                <span class="haederBlogDetail text-color-999 pr-1">دیدگاه</span>
              </div>
            </div>
            <hr />
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <b-skeleton-img v-if="blogStatus"></b-skeleton-img>
            <template v-else>
              <img
                v-if="blog.post.image != null"
                :src="blog.post.image.url"
                :alt="blog.post.title"
                class="articleImage"
              />
            </template>
            <br v-if="blogStatus" />
            <h5 v-else class="text-color-444 mt-5 mb-4">
              {{ blog.post.summary }}
            </h5>
            <b-skeleton-img v-if="blogStatus"></b-skeleton-img>
            <div v-else v-html="blog.post.body"></div>
            <!-- <hr />
            <div class="d-flex justify-content-between">
              <span class="fontsize14 weight-bold ml-3 flex-shrink-0">برچسب ها:</span>
              <div  class="fontsize12 text-color-999">
                <span>#لباس</span>
                <span>#کفش</span>
                <span>#لباس-گرم</span>
                <span>#لباس-تابستانی</span>
                <span>#لباس</span>
                <span>#کفش</span>
                <span>#لباس-گرم</span>
                <span>#لباس-تابستانی</span>
              </div>
            </div> -->
            <hr />
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <div v-else class="d-flex justify-content-between">
              <span class="fontsize-small weight-bold ml-3"
                >اشتراک گذاری مطلب :</span
              >
              <section class="sotial-networks">
                <a
                  :href="
                    'whatsapp://send?text=' + encodeURIComponent(getBlogLink())
                  "
                  class="mx-2"
                >
                  <svg
                    id="Capa_1"
                    height="18"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="18"
                    x="0px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    y="0px"
                  >
                    <path
                      d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
                      fill="#bbb"
                    />
                  </svg>
                </a>
                <!-- <a href="" class="mx-2">
                  <svg width="15" height="15" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">

                            <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                                C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                                h192c61.76,0,112,50.24,112,112V352z" fill="#bbb"/>
                    <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                                c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z" fill="#bbb"/>
                    <circle cx="393.6" cy="118.4" r="17.056" fill="#bbb"/>
                    </svg>
                </a> -->
                <a
                  :href="
                    'http://twitter.com/share?url=' +
                    encodeURIComponent(getBlogLink())
                  "
                  class="mx-2"
                >
                  <svg
                    id="Capa_1"
                    height="18"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="18"
                    x="0px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    y="0px"
                  >
                    <path
                      d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                C480.224,136.96,497.728,118.496,512,97.248z"
                      fill="#bbb"
                    />
                  </svg>
                </a>
                <!-- <a href="" class="mx-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                    <path fill="#bbb" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                  </svg>
                </a> -->
                <a
                  :href="
                    'https://www.facebook.com/sharer/sharer.php?u=' +
                    encodeURIComponent(getBlogLink())
                  "
                  class="mx-2"
                >
                  <svg
                    id="Capa_1"
                    height="18"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="18"
                    x="0px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    y="0px"
                  >
                    <path
                      d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80
                                h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"
                      fill="#bbb"
                    />
                  </svg>
                </a>
              </section>
            </div>
          </section>
          <b-skeleton v-if="blogStatus"></b-skeleton>
          <section
            v-else-if="blog && blog.suggests != ''"
            class="similar-articles"
          >
            <div
              class="d-flex justify-content-between align-items-baseline mb-3"
            >
              <h5 class="fontsize-heavy">مطالب مشابه</h5>
              <router-link
                class="recommended-products-more fontsize-small text-white bg-color-a5 px-3 py-2 mt-3 d-inline-block"
                to="/weblog-list"
                >مشاهده همه پست ها
              </router-link>
            </div>

            <section class="weblog-list-main-grid">
              <!-- افزودن پست های پیشنهادی با ارسال داده به صورت پراپس -->
              <Weblog
                v-for="(suggest, index) in blog.suggests"
                :key="'suggest' + index"
                :weblog="suggest"
              ></Weblog>
            </section>
          </section>
          <CommentSection />
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
      <FooterButtons></FooterButtons>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import FooterButtons from "@/parts/Front/components/FooterButtons";
import PagesPath from "@/parts/Front/components/PagesPath";
import Weblog from "@/parts/Front/components/Weblog";
import CommentSection from "@/parts/Front/components/weblog/CommentSection";
import { BSkeleton, BSkeletonImg } from "bootstrap-vue";

export default {
  name: "WblogDetails",
  components: {
    CommentSection,
    ScrollToTop,
    FooterButtons,
    PagesPath,
    Weblog,
    BSkeleton,
    BSkeletonImg,
  },
  beforeCreate() {
    this.$store.dispatch(
      "front/getBlogDetailFromServer",
      this.$route.params.id
    );
  },
  computed: {
    blog() {
      return this.$store.getters["front/getBlogDetail"];
    },
    blogStatus() {
      return this.$store.getters["front/getBlogDetailStatus"];
    },
  },
  data() {
    return {};
  },
  methods: {
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getBlogLink() {
      // گرفتن لینک این بلاگ برای اشتراک گزاری
      if (!this.blog.post) {
        return "";
      }
      return (
        window.location.hostname +
        "/blogs/" +
        this.blog.post.id +
        "/" +
        this.blog.post.slug
      );
    },

    // تابع زیر برای اضافه کردن متا تگ و بالا بردن سئو است
  },
  mounted() {
    // در صورتی که کابر لاگین کرده بود و ایمیل داشت مقدار پیشفرض ایمیل را همان مفدار نشان بده
    if (this.$store.getters["front/getHomeData"] != null) {
      if (this.$store.getters["front/getHomeData"].user.user != false) {
        if (this.$store.getters["front/getHomeData"].user.user.email != null) {
          this.email = this.$store.getters["front/getHomeData"].user.user.email;
        }
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: this.blog ? this.blog.post.meta_description : false,
        },
        {
          property: "og:title",
          content: this.blog ? this.blog.post.title : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style scoped>
.inputAlert {
  background-color: rgb(255 250 244);
}
/* افزودن استایل به کدهای اضافی قالب */
.articleImage {
  width: 100%;
  object-fit: fill;
}
.haederBlogDetail {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.weblog-details >>> img {
  max-width: 100%;
}
@media screen and (max-width: 550px) {
  .weblog-details >>> img {
    height: auto !important;
  }
}
</style>
