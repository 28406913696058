<template>
  <section
    class="recommended-products  pt-3 mt-2 mt-sm-1 mb-sm-5 mb-4"
  >
    <div class="d-flex flex-wrap justify-content-between align-items-baseline">
      <h6 class="font-weight-bold text-color-444 ml-3 fontsize-large">
        پیشنهادی نی نی پلاس
      </h6>
      <!-- <div class="line w-50"></div> -->
      <div class="recommended-products-category d-flex">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li
            v-for="(tab, index) in tabs"
            @click="setActiveProducts(tab)"
            :key="index"
            class="nav-item ml-1"
            role="presentation"
          >
            <a
              class="nav-link py-1 px-md-3 px-2 fontsize-medium bg-none text-color-444"
              :class="{ active: activeTab.name == tab.name }"
              id="home-tab"
              data-toggle="tab"
              href="#productList"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              >{{ tab.label }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="recommended-content tab-content mt-5" id="myTabContent">
      <div
        class="recommended-content-item tab-pane fade show active"
        id="productList"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div v-if="products" class="recommended-content-grid">
          <Product
            v-for="(product, index) in productList"
            :key="index"
            :product="product"
          ></Product>
        </div>
        <Loading v-else />

        <div class="text-center">
          <router-link
            to="/products?sort=newest"
            class="recommended-products-more fontsize-small text-white bg-color-theme px-3 py-2 mt-3 d-inline-block"
            >مشاهده همه محصولات</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import Loading from "@/parts/Front/components/shared/Loading";

export default {
  name: "RecommendedProduct",
  components: {
    Product,
    Loading,
  },
  data() {
    return {
      products: null,
      tabs: [
        {
          name: "new-products",
          label: "جدیدترین",
        },
        {
          name: "most-discounts",
          label: " تخفیفدار",
        },

        {
          name: "suggestions",
          label: "پیشنهادی",
        },
        {
          name: "most-sales",
          label: "پر فروش ترین",
        },
      ],
      activeTab: {
        name: "new-products",
        label: "جدیدترین",
      },
    };
  },
  inject: ["mediaQueries"],

  mounted() {
    this.setActiveProducts(this.activeTab);
  },
  methods: {
    async setActiveProducts(tab) {
      this.activeTab = tab;
      this.products = null;
      let res = await this.$store.dispatch(
        "front/getIndexPageContent",
        tab.name
      );
      this.products = res;
    },
  },
  computed: {
    productList() {
      return this.products;
    },
  },
};
</script>

<style scoped>
@media screen and(max-width: 500px) {
  .recommended-products-category a.fontsize-medium {
    font-size: 13px !important;
  }
}
/*.home-product-list {
  display: grid;
  grid-template-columns: repeat(4, 23%);
  gap: 1.5rem calc((100% - (4 * 23%)) / 3);
}
@media (max-width: 992px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(3, 31%);
    gap: 1.5rem calc((100% - (3 * 31%)) / 2);
  }
}
@media (max-width: 768px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 1.5rem calc((100% - (2 * 48%)));
  }
}
@media (max-width: 360px) {
  .home-product-list {
    display: grid;
    grid-template-columns: repeat(1, 100%);
  }
} */
</style>
