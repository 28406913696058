<template>
  <div>
    <main class="main product-details-main">
      <section
        class="pages-path d-md-flex align-items-baseline fontsize12 text-white bg-color-theme py-2 d-none"
      >
        <div class="container text-right">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-width="1.5"
              stroke="#fff"
              d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            fill="currentColor"
            class="ml-2 bi bi-chevron-double-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          <router-link to="/" style="color: white" class="fontsize-mini">
            <span>خانه</span></router-link
          >
          <svg
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mt-n1"
          >
            <path
              d="M15.5 19L8.5 12L15.5 5"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="fontsize-mini">پست ها</span>
        </div>
      </section>

      <div class="weblog-list-grid container text-right mt-md-5 mt-3">
        <div class="weblog-list-main">
          <b-skeleton v-if="status"></b-skeleton>
          <div v-else>
            <!-- باکس جستوجو پست -->
            <div id="seatchBlogSection">
              <button
                @click.prevent="blogSearch()"
                type="button"
                class="d-md-inline-block d-none bg-none ml-2"
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.7666"
                    cy="11.7666"
                    r="8.98856"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.0183 18.4851L21.5423 22"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <input type="text" id="seacrchInBlogs" v-model="search" />
              <b-spinner
                v-if="spinnerSearch"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </div>
            <hr id="hrSearch" />
          </div>

          <div v-if="status">
            <b-row>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
            </b-row>
          </div>
          <section v-else class="weblog-list-main-grid">
            <template v-if="blogs && blogs.posts.data.length != 0">
              <Weblog
                v-for="(weblog, index) in blogs.posts.data"
                :key="index"
                :weblog="weblog"
              ></Weblog>
            </template>
            <div v-else>
              <span>پستی برای نمایش وجود ندارد</span>
            </div>
          </section>
          <b-skeleton v-if="status"></b-skeleton>
          <section
            id="mainPaginationBlog"
            v-else
            class="weblog-list-pagination text-center my-4 p-2 bg-color-fa"
          >
            <section
              v-if="blogs && blogs.posts != ''"
              id="listPagination"
              class="list-pagination weblog-list-pagination text-center my-4 py-2"
            >
              <pagination
                :limit="1"
                :data="blogs.posts"
                @pagination-change-page="changePage"
                :disabled="disabled"
                class="justify-content-center"
              >
                <span slot="prev-nav">
                  <ZPrevSvg />
                </span>
                <span slot="next-nav">
                  <ZNextSvg />
                </span>
              </pagination>
            </section>
            <!-- <pagination :data="blogs.posts.links" :router="true" @pagination-change-page="changePage"></pagination> -->
          </section>
        </div>
        <div class="welog-list-side">
          <b-skeleton-img v-if="status"></b-skeleton-img>
          <br v-if="status" />

          <section
            v-else
            class="weblog-categories box-shaddow20 p-3 bg-white mb-3"
          >
            <!-- دسته بندی پست ها -->
            <div class="d-flex border-bottom-dashed pb-2">
              <h6 class="mr-2 weight-bold text-color-444 fontsize-medium">
                دسته بندی ها
              </h6>
            </div>
            <ul class="py-3">
              <!-- دریافت از کامپیوت پایین -->
              <li
                v-for="(category, index) in blogs.category"
                :key="'category' + index"
              >
                <router-link
                  class="fontsize-small"
                  :to="{
                    name: 'weblogList',
                    params: { id: category.id, slug: category.slug },
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    fill="currentColor"
                    class="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  {{ category.name }}
                </router-link>
              </li>
            </ul>
          </section>
          <!-- <section
            class="
              advertisting1
              bg-color-ea

              overflow-hidden
              box-shaddow20
              text-center
              mb-3
            "
          >
            <a href=""><img src="@/assets/images/banner1.png" alt="" /></a>
          </section> -->

          <!--
          <section

            class="
              weblog-last-articles

              box-shaddow20
              p-3
              bg-white
              mb-3
            "
          >
            <div class="d-flex border-bottom-dashed pb-2 mb-3">
              <h5 class="mr-2 fontsize18 weight-bold text-color-444">
                آخرین مطالب
              </h5>
            </div>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art3.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art2.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art3.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art2.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art3.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art2.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
          </section> -->
        </div>
      </div>
    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import Weblog from "@/parts/Front/components/Weblog";
import mixin from "@@/core/mixins/mixins.js";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";
import { BSkeletonImg, BRow, BCol, BSpinner, BSkeleton } from "bootstrap-vue";
export default {
  name: "WeblogList",
  mixins: [mixin],
  components: {
    ZNextSvg,
    ZPrevSvg,
    Weblog,
    BSkeletonImg,
    BRow,
    BCol,
    BSpinner,
    BSkeleton,
  },
  data() {
    return { search: null, spinnerSearch: false, selectedCategoryId: null };
  },
  mounted() {
    //get height of advertisting1
    const advertisting1 = document.querySelectorAll(".advertisting1")[0];
    if (advertisting1) {
      advertisting1.style.height = advertisting1.offsetWidth / 1.5 + "px";
    }

    window.addEventListener("resize", function () {
      const advertisting1 = document.querySelectorAll(".advertisting1")[0];
      if (advertisting1) {
        advertisting1.style.height = advertisting1.offsetWidth / 1.5 + "px";
      }

      // get height of articles/
      const articleImg = document.querySelectorAll(".article-img");
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    });

    // get height of articles/
    let bodyWidth = document.body.offsetWidth;
    const articleImg = document.querySelectorAll(".article-img");
    if (bodyWidth < 767) {
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    }
  },
  created() {
    // در صورت خالی بودن لیست پست ها اطلاعات از سمت سرور فتچ شود
    if (this.blogs == null) {
      // اگر دسته بندی انتخاب شده باشد
      if (this.$route.params.id) {
        const data = {
          category: this.$route.params.id,
        };
        // و اگر صفحه بندی نیز انتخاب شده باشد
        if (this.$route.query.page) {
          data.page = this.$route.query.page;
        }
        // ارسال درخواست
        this.$store.dispatch("front/getAllBlogFromServer", data);
      } else {
        // اگر دسته بندی انتخاب نشده باشد
        const data = {};
        // ولی اگر صفحه بندی داشت
        if (this.$route.query.page) {
          data.page = this.$route.query.page;
        }
        this.$store.dispatch("front/getAllBlogFromServer", data);
      }
    }
  },
  computed: {
    // دریافت اطلاعات از ویو ایکس برای لیست پست ها
    blogs() {
      return this.$store.getters["front/getAllBlog"];
    },
    status() {
      // برای نمایش لودر ها
      return this.$store.getters["front/getStatusAllBlog"];
    },
  },
  methods: {
    showBlogs(link) {
      if (link == null) return;
      // page=             پیدا کردن عدد مقابل کوئری
      // نمایش در یو آر ال و صدا زدن تابع فتچ
      let pageIndex = link.search("page=");
      let page = link.slice(pageIndex + 5);
      this.$router.replace({ name: "weblogList", query: { page: page } });
      // this.$route.query.page=page;
      // let data={
      //   page:page
      // };
      // if(this.$route.params.id){
      //   data.category=this.$route.params.id
      // }
      //  this.$store.dispatch("front/getAllBlogFromServer",data);
    },
    blogSearch() {
      this.spinnerSearch = true;

      if (this.search != null) {
        //با کلیک روی دکمه سرچ جستوجو در بلاگ ها صورت میگیرد
        this.$store.dispatch("front/searchInBlog", this.search);
      } else {
        // در صورت خالی بودن فوکز شود روی اینپوت
        document.getElementById("seacrchInBlogs").focus();
        this.spinnerSearch = false;
      }
    },
    // تابع زیر برای اضافه کردن متا تگ و بالا بردن سئو است
  },
  watch: {
    blogs(newVal, oldVal) {
      if (newVal != oldVal) {
        //برای غیر فعالسازی اسپینر هنگام سرچ
        this.spinnerSearch = false;
      }
    },
    "$route.params.id": {
      handler: function (val) {
        const data = {
          category: val,
        };
        if (this.$route.query.page) {
          data.page = this.$route.query.page;
        }
        this.$store.dispatch("front/getAllBlogFromServer", data);
      },
      immediate: true,
    },
    "$route.query.page": {
      handler: function (val) {
        const data = {
          page: val,
        };
        if (this.$route.params.id) {
          data.category = this.$route.params.id;
        }
        this.$store.dispatch("front/getAllBlogFromServer", data);
      },
      immediate: true,
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: this.blogs.posts.data
            ? this.blogs.posts.data[0].name
            : false,
        },
        {
          property: "og:title",
          content: this.blogs.posts.data[0]
            ? this.blogs.posts.data[0].name
            : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style scoped>
#seatchBlogSection {
  display: flex;
}
#hrSearch {
  margin-top: 3px;
}
#seacrchInBlogs {
  flex-grow: 10;
}
/* @media (max-width:991px){
  #mainPaginationBlog{
    display: none!important;
  }
} */
/* افزودن استایل به کامپوننت اضافه شده  */
.pagination {
  justify-content: center;
  flex-direction: row;
}

/*تعمیر قالب   */
#mainPaginationBlog {
  background: none !important;
}
#listPagination {
  background: none !important;
}
</style>
